import * as React from 'react'
import { Link } from 'gatsby'
import { AuthLayout } from '../components/AuthLayout'
import Button from '../components/Button'
import { TextField } from '../components/Fields'
import CafeZincanLogo from '../../assets/svgs/CafeZincanLogo.svg'

const WaitingList = () => {
    const emailSignupUrl = `https://api.zincan.com/iou/email_signups`
  return (
    <>
      <AuthLayout>
        <div className="flex flex-col">
          <Link to="/" aria-label="Home">
            <CafeZincanLogo />
          </Link>
          <div className="mt-20">
            <h2 className="text-lg font-semibold text-gray-900">
              Join the waiting list
            </h2>
            <p className="mt-2 text-sm text-gray-700">
              No commitment – we'll just notify you when we're ready for your site.
            </p>
          </div>
        </div>
        <form
          action={emailSignupUrl}
          method="POST"
          className="mt-10 grid grid-cols-1 gap-y-8 gap-x-6 sm:grid-cols-2"
        >
          <TextField
            label="First name"
            id="first_name"
            name="first_name"
            type="text"
            autoComplete="given-name"
            required
          />
          <TextField
            label="Last name"
            id="last_name"
            name="last_name"
            type="text"
            autoComplete="family-name"
            required
          />
          <TextField
            className="col-span-full"
            label="Your site"
            id="site"
            name="site"
            type="text"
            required
          />
          <TextField
            className="col-span-full"
            label="Email address"
            id="email"
            name="email"
            type="email"
            autoComplete="email"
            required
          />
          <div className="col-span-full pb-32">
            <Button
              type="submit"
              variant="solid"
              color="primary"
              className="w-full">
              <span>
                Join the waiting list <span aria-hidden="true">&rarr;</span>
              </span>
            </Button>
          </div>
        </form>
      </AuthLayout>
    </>
  )
}

export default WaitingList;

export const Head = () => <title>Café Zincan - Waiting List</title>;

